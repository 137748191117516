@import '../../global/styles/lib';

$search-transition: 750ms ease-in-out;
$search-size: 2rem;
$search-size-medium: 2.5rem;

.search-display {
    position: relative;
    width: 100%;

    .search-submit {
        position: relative;
        display: flex;
        width: 100%;
        height: 3rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all $compact-transition;
        
        div {
            @include absolute-full;
            opacity: 0;
            overflow: hidden;
            display: flex;
            align-items: center;
            transition: opacity $search-transition;

            svg {
                background: $transparent;
                border-radius: 50%;
                width: 100%;
                height: auto;
                fill: $black;
                transition: all $compact-transition;
                
                path {
                    stroke: $black;
                    stroke-linecap: round;
                    transition: all 0.5s linear;
                }
                
                .handle {
                    stroke-dashoffset: -14px;
                    stroke-dasharray: 14px;
                }
                
                .circle {
                    stroke-dashoffset: 0;
                    stroke-dasharray: 34px;
                } // .circle
                
                &.is-open {
                    .handle {
                        stroke-dashoffset: -2px;
                        stroke-dasharray: 13px;
                    }
                    .circle {
                        stroke-dashoffset: -54px;
                        stroke-dasharray: 54px;
                    } // .circle
                } // &.is-open
            }

            &.is-open {
                opacity: 1;
            } // &.is-open

        } // div

        #{$hovfoc} {
            div {
                svg {
                    //background: $gray-dark;
                } // svg
            } // div
        } // hover focus
        

    } // .search-submit

} // .search-display

.search-display__form {
    @include absolute-full(fixed);
    background: rgba($white, 0.95);
    @supports #{$backdrop-filter-support} {
        @include backdrop-blur;
    } // backdrop-filter-support

    display: none;
    opacity: 0;
    transition: opacity $search-transition;

    &.is-open {
        opacity: 1;        
    } // .is-open

    .content {
        @include absolute-full;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: 30vh;
        
        form {
            padding-left: 1rem;
            padding-right: 1rem;
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 45rem;
            justify-content: space-between;
            align-items: center;

            & > label {
                width: 65%;
                margin-right: 1rem;
                input {
                    margin: 0;
                } // input

                @include breakpoint(medium up) {
                    width: 75%;
                } // large up

                @include breakpoint(large up) {
                    width: 85%;
                    margin-right: 1rem;
                } // large up

            } // & > label

            & > input {
                width: 4rem;
                height: 2rem;
            } // & > input

        } // form

    } // .content

    .search-field {
        font-size: 0.85rem;
        &::placeholder {
            font-size: 0.85rem;
        } // &::placeholder

        @include breakpoint(medium up) {
            font-size: 1rem;
            &::placeholder {
                font-size: 1rem;
            } // &::placeholder
        }
    }

} // .search-display__form